<template>
  <div>
    <v-stripe-card v-model="source" :api-key="stripe" create="source"></v-stripe-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sample",
  data() {
    return {
      source: null
    };
  },
  computed: {
    ...mapState({
      stripe: state => state.stripePublishableKey
    })
  }
};
</script>

<style>
</style>